import "./liveTile.scss";

import { Integrale } from "~models/integrale";
import { Unit } from "~models/unit";
import { getChannelLogo } from "~tools/getChannelPics";
import { checkImage } from "~tools/tools";
import { DOMHelper, Keys, View } from "~ui-lib";

export class LiveTileView extends View {
  item: Integrale | Unit;
  isPlaying: boolean;

  constructor(item: Integrale | Unit, showMeta = true, isPlaying = false) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemLiveVignette"));
    this.isPlaying = isPlaying;
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemLiveViewBoxZoom");
    this.item = item;
    const elementImg = DOMHelper.createDivImg(
      box,
      null,
      "elementImg",
      require("~images/fallbacks/fallback-vignette.png")
    );
    DOMHelper.createDivImg(elementImg, null, "iconBackground");

    const tileImgUrl = this.item.getLandscapeTileImgUrl();
    checkImage(
      tileImgUrl,
      () => {
        elementImg.style.backgroundImage = `url("${tileImgUrl}")`;
      },
      () => {}
    );

    const broadcastInfo =
      item.media && item.media.broadcast && item.media.broadcast.start_date && item.media.broadcast.end_date
        ? item.media.broadcast
        : null;
    if (broadcastInfo) {
      const liveTimeline = DOMHelper.createDivWithParent(elementImg, null, "liveTimeline");
      const timelinePercent = DOMHelper.createDivWithParent(liveTimeline, null, "liveTimelinePercent", null);

      const startDate = broadcastInfo.start_date.getTime(),
        endDate = broadcastInfo.end_date.getTime(),
        currentDate = Date.now();

      let percent: number;
      if (startDate >= currentDate) {
        percent = 0;
      } else if (endDate <= currentDate) {
        percent = 100;
      } else {
        const liveTextBox = DOMHelper.createDivWithParent(elementImg, null, "liveTextBox");
        const liveTextIconElt = DOMHelper.createDivWithParent(liveTextBox, null, "liveTextEllipse");
        liveTextIconElt.style.backgroundImage = `url(${require("~images/unitaireTab/Ellipse.png")})`;
        DOMHelper.createDivWithParent(liveTextBox, null, "liveText", "direct");

        percent = Math.floor(((currentDate - startDate) / (endDate - startDate)) * 100);
      }
      timelinePercent.style.width = percent + "%";

      percent === 100 && DOMHelper.addClass(timelinePercent, "completed");
    }

    const channelLogo = getChannelLogo(item);
    if (channelLogo) {
      const infoChannelIconElt = DOMHelper.createDivImg(elementImg, null, "infoChannelIcon");
      infoChannelIconElt.style.backgroundImage = `url(${channelLogo})`;
    } else {
      if (item.extras && item.extras.event) {
        const artworksCarre = this.item.getSquareImgUrl();
        if (artworksCarre && artworksCarre.length) {
          DOMHelper.createDivImg(elementImg, null, "infoChannelIcon", artworksCarre);
        }
      } else {
        DOMHelper.createDivImg(elementImg, null, "infoChannelIcon", require("~images/channels/logo/france-tv.png"));
      }
    }

    if (this.item.metadata && this.item.metadata.rating && this.item.metadata.rating.code) {
      const picto = this.item.metadata.rating.picto();
      if (picto) DOMHelper.createDivImg(elementImg, null, "pictosCsa", picto);
    }

    if (showMeta) {
      item.extras.program?.title &&
        DOMHelper.createDivWithParent(box, null, "liveMetadataTitle", item.extras.program.title);
      DOMHelper.createDivWithParent(box, null, "liveMetadataSubtitle", item.extras?.episode_title || item.title);

      if (broadcastInfo) {
        const _formatTime = function (hour: number): string {
          return hour < 10 ? "0" + hour : "" + hour;
        };
        const startDate = broadcastInfo.start_date,
          endDate = broadcastInfo.end_date;
        const startDateString = "de " + _formatTime(startDate.getHours()) + "h" + _formatTime(startDate.getMinutes()),
          endDateString = " à " + _formatTime(endDate.getHours()) + "h" + _formatTime(endDate.getMinutes());
        DOMHelper.createDivWithParent(box, null, "liveMetadataDate", startDateString + endDateString);
      }
    }
    if (this.isPlaying) {
      const mask = DOMHelper.createDivWithParent(elementImg, null, "elementImgMask");
      DOMHelper.createSpanWithParent(mask, null, "maskText", "en cours de lecture");
    }
  }

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.select:
        if (this.isPlaying) return true;
        return false;
      default:
        return false;
    }
  };
}
